const icons: string[] = [
  "fas fa-0",
  "fas fa-1",
  "fas fa-2",
  "fas fa-3",
  "fas fa-4",
  "fab fa-42-group",
  "fas fa-5",
  "fab fa-500px",
  "fas fa-6",
  "fas fa-7",
  "fas fa-8",
  "fas fa-9",
  "fas fa-a",
  "fab fa-accessible-icon",
  "fab fa-accusoft",
  "far fa-address-book",
  "fas fa-address-book",
  "far fa-address-card",
  "fas fa-address-card",
  "fab fa-adn",
  "fab fa-adversal",
  "fab fa-affiliatetheme",
  "fab fa-airbnb",
  "fab fa-algolia",
  "fas fa-align-center",
  "fas fa-align-justify",
  "fas fa-align-left",
  "fas fa-align-right",
  "fab fa-alipay",
  "fab fa-amazon",
  "fab fa-amazon-pay",
  "fab fa-amilia",
  "fas fa-anchor",
  "fab fa-android",
  "fab fa-angellist",
  "fas fa-angle-down",
  "fas fa-angle-left",
  "fas fa-angle-right",
  "fas fa-angle-up",
  "fas fa-angles-down",
  "fas fa-angles-left",
  "fas fa-angles-right",
  "fas fa-angles-up",
  "fab fa-angrycreative",
  "fab fa-angular",
  "fas fa-ankh",
  "fab fa-app-store",
  "fab fa-app-store-ios",
  "fab fa-apper",
  "fab fa-apple",
  "fab fa-apple-pay",
  "fas fa-apple-whole",
  "fas fa-archway",
  "fas fa-arrow-down",
  "fas fa-arrow-down-1-9",
  "fas fa-arrow-down-9-1",
  "fas fa-arrow-down-a-z",
  "fas fa-arrow-down-long",
  "fas fa-arrow-down-short-wide",
  "fas fa-arrow-down-wide-short",
  "fas fa-arrow-down-z-a",
  "fas fa-arrow-left",
  "fas fa-arrow-left-long",
  "fas fa-arrow-pointer",
  "fas fa-arrow-right",
  "fas fa-arrow-right-arrow-left",
  "fas fa-arrow-right-from-bracket",
  "fas fa-arrow-right-long",
  "fas fa-arrow-right-to-bracket",
  "fas fa-arrow-rotate-left",
  "fas fa-arrow-rotate-right",
  "fas fa-arrow-trend-down",
  "fas fa-arrow-trend-up",
  "fas fa-arrow-turn-down",
  "fas fa-arrow-turn-up",
  "fas fa-arrow-up",
  "fas fa-arrow-up-1-9",
  "fas fa-arrow-up-9-1",
  "fas fa-arrow-up-a-z",
  "fas fa-arrow-up-from-bracket",
  "fas fa-arrow-up-long",
  "fas fa-arrow-up-right-from-square",
  "fas fa-arrow-up-short-wide",
  "fas fa-arrow-up-wide-short",
  "fas fa-arrow-up-z-a",
  "fas fa-arrows-left-right",
  "fas fa-arrows-rotate",
  "fas fa-arrows-up-down",
  "fas fa-arrows-up-down-left-right",
  "fab fa-artstation",
  "fas fa-asterisk",
  "fab fa-asymmetrik",
  "fas fa-at",
  "fab fa-atlassian",
  "fas fa-atom",
  "fab fa-audible",
  "fas fa-audio-description",
  "fas fa-austral-sign",
  "fab fa-autoprefixer",
  "fab fa-avianex",
  "fab fa-aviato",
  "fas fa-award",
  "fab fa-aws",
  "fas fa-b",
  "fas fa-baby",
  "fas fa-baby-carriage",
  "fas fa-backward",
  "fas fa-backward-fast",
  "fas fa-backward-step",
  "fas fa-bacon",
  "fas fa-bacteria",
  "fas fa-bacterium",
  "fas fa-bag-shopping",
  "fas fa-bahai",
  "fas fa-baht-sign",
  "fas fa-ban",
  "fas fa-ban-smoking",
  "fas fa-bandage",
  "fab fa-bandcamp",
  "fas fa-barcode",
  "fas fa-bars",
  "fas fa-bars-progress",
  "fas fa-bars-staggered",
  "fas fa-baseball",
  "fas fa-baseball-bat-ball",
  "fas fa-basket-shopping",
  "fas fa-basketball",
  "fas fa-bath",
  "fas fa-battery-empty",
  "fas fa-battery-full",
  "fas fa-battery-half",
  "fas fa-battery-quarter",
  "fas fa-battery-three-quarters",
  "fab fa-battle-net",
  "fas fa-bed",
  "fas fa-bed-pulse",
  "fas fa-beer-mug-empty",
  "fab fa-behance",
  "far fa-bell",
  "fas fa-bell",
  "fas fa-bell-concierge",
  "far fa-bell-slash",
  "fas fa-bell-slash",
  "fas fa-bezier-curve",
  "fas fa-bicycle",
  "fab fa-bilibili",
  "fab fa-bimobject",
  "fas fa-binoculars",
  "fas fa-biohazard",
  "fab fa-bitbucket",
  "fab fa-bitcoin",
  "fas fa-bitcoin-sign",
  "fab fa-bity",
  "fab fa-black-tie",
  "fab fa-blackberry",
  "fas fa-blender",
  "fas fa-blender-phone",
  "fas fa-blog",
  "fab fa-blogger",
  "fab fa-blogger-b",
  "fab fa-bluetooth",
  "fab fa-bluetooth-b",
  "fas fa-bold",
  "fas fa-bolt",
  "fas fa-bolt-lightning",
  "fas fa-bomb",
  "fas fa-bone",
  "fas fa-bong",
  "fas fa-book",
  "fas fa-book-atlas",
  "fas fa-book-bible",
  "fas fa-book-journal-whills",
  "fas fa-book-medical",
  "fas fa-book-open",
  "fas fa-book-open-reader",
  "fas fa-book-quran",
  "fas fa-book-skull",
  "far fa-bookmark",
  "fas fa-bookmark",
  "fab fa-bootstrap",
  "fas fa-border-all",
  "fas fa-border-none",
  "fas fa-border-top-left",
  "fab fa-bots",
  "fas fa-bowling-ball",
  "fas fa-box",
  "fas fa-box-archive",
  "fas fa-box-open",
  "fas fa-box-tissue",
  "fas fa-boxes-stacked",
  "fas fa-braille",
  "fas fa-brain",
  "fas fa-brazilian-real-sign",
  "fas fa-bread-slice",
  "fas fa-briefcase",
  "fas fa-briefcase-medical",
  "fas fa-broom",
  "fas fa-broom-ball",
  "fas fa-brush",
  "fab fa-btc",
  "fab fa-buffer",
  "fas fa-bug",
  "fas fa-bug-slash",
  "far fa-building",
  "fas fa-building",
  "fas fa-building-columns",
  "fas fa-bullhorn",
  "fas fa-bullseye",
  "fas fa-burger",
  "fab fa-buromobelexperte",
  "fas fa-bus",
  "fas fa-bus-simple",
  "fas fa-business-time",
  "fab fa-buy-n-large",
  "fab fa-buysellads",
  "fas fa-c",
  "fas fa-cake-candles",
  "fas fa-calculator",
  "far fa-calendar",
  "fas fa-calendar",
  "far fa-calendar-check",
  "fas fa-calendar-check",
  "fas fa-calendar-day",
  "far fa-calendar-days",
  "fas fa-calendar-days",
  "far fa-calendar-minus",
  "fas fa-calendar-minus",
  "far fa-calendar-plus",
  "fas fa-calendar-plus",
  "fas fa-calendar-week",
  "far fa-calendar-xmark",
  "fas fa-calendar-xmark",
  "fas fa-camera",
  "fas fa-camera-retro",
  "fas fa-camera-rotate",
  "fas fa-campground",
  "fab fa-canadian-maple-leaf",
  "fas fa-candy-cane",
  "fas fa-cannabis",
  "fas fa-capsules",
  "fas fa-car",
  "fas fa-car-battery",
  "fas fa-car-rear",
  "fas fa-car-side",
  "fas fa-caravan",
  "fas fa-caret-down",
  "fas fa-caret-left",
  "fas fa-caret-right",
  "fas fa-caret-up",
  "fas fa-carrot",
  "fas fa-cart-arrow-down",
  "fas fa-cart-flatbed",
  "fas fa-cart-flatbed-suitcase",
  "fas fa-cart-plus",
  "fas fa-cart-shopping",
  "fas fa-cash-register",
  "fas fa-cat",
  "fab fa-cc-amazon-pay",
  "fab fa-cc-amex",
  "fab fa-cc-apple-pay",
  "fab fa-cc-diners-club",
  "fab fa-cc-discover",
  "fab fa-cc-jcb",
  "fab fa-cc-mastercard",
  "fab fa-cc-paypal",
  "fab fa-cc-stripe",
  "fab fa-cc-visa",
  "fas fa-cedi-sign",
  "fas fa-cent-sign",
  "fab fa-centercode",
  "fab fa-centos",
  "fas fa-certificate",
  "fas fa-chair",
  "fas fa-chalkboard",
  "fas fa-chalkboard-user",
  "fas fa-champagne-glasses",
  "fas fa-charging-station",
  "fas fa-chart-area",
  "far fa-chart-bar",
  "fas fa-chart-bar",
  "fas fa-chart-column",
  "fas fa-chart-gantt",
  "fas fa-chart-line",
  "fas fa-chart-pie",
  "fas fa-check",
  "fas fa-check-double",
  "fas fa-check-to-slot",
  "fas fa-cheese",
  "fas fa-chess",
  "far fa-chess-bishop",
  "fas fa-chess-bishop",
  "fas fa-chess-board",
  "far fa-chess-king",
  "fas fa-chess-king",
  "far fa-chess-knight",
  "fas fa-chess-knight",
  "far fa-chess-pawn",
  "fas fa-chess-pawn",
  "far fa-chess-queen",
  "fas fa-chess-queen",
  "far fa-chess-rook",
  "fas fa-chess-rook",
  "fas fa-chevron-down",
  "fas fa-chevron-left",
  "fas fa-chevron-right",
  "fas fa-chevron-up",
  "fas fa-child",
  "fab fa-chrome",
  "fab fa-chromecast",
  "fas fa-church",
  "far fa-circle",
  "fas fa-circle",
  "fas fa-circle-arrow-down",
  "fas fa-circle-arrow-left",
  "fas fa-circle-arrow-right",
  "fas fa-circle-arrow-up",
  "far fa-circle-check",
  "fas fa-circle-check",
  "fas fa-circle-chevron-down",
  "fas fa-circle-chevron-left",
  "fas fa-circle-chevron-right",
  "fas fa-circle-chevron-up",
  "fas fa-circle-dollar-to-slot",
  "far fa-circle-dot",
  "fas fa-circle-dot",
  "far fa-circle-down",
  "fas fa-circle-down",
  "fas fa-circle-exclamation",
  "fas fa-circle-h",
  "fas fa-circle-half-stroke",
  "fas fa-circle-info",
  "far fa-circle-left",
  "fas fa-circle-left",
  "fas fa-circle-minus",
  "fas fa-circle-notch",
  "far fa-circle-pause",
  "fas fa-circle-pause",
  "far fa-circle-play",
  "fas fa-circle-play",
  "fas fa-circle-plus",
  "far fa-circle-question",
  "fas fa-circle-question",
  "fas fa-circle-radiation",
  "far fa-circle-right",
  "fas fa-circle-right",
  "far fa-circle-stop",
  "fas fa-circle-stop",
  "far fa-circle-up",
  "fas fa-circle-up",
  "far fa-circle-user",
  "fas fa-circle-user",
  "far fa-circle-xmark",
  "fas fa-circle-xmark",
  "fas fa-city",
  "fas fa-clapperboard",
  "far fa-clipboard",
  "fas fa-clipboard",
  "fas fa-clipboard-check",
  "fas fa-clipboard-list",
  "fas fa-clipboard-user",
  "far fa-clock",
  "fas fa-clock",
  "fas fa-clock-rotate-left",
  "far fa-clone",
  "fas fa-clone",
  "far fa-closed-captioning",
  "fas fa-closed-captioning",
  "fas fa-cloud",
  "fas fa-cloud-arrow-down",
  "fas fa-cloud-arrow-up",
  "fas fa-cloud-meatball",
  "fas fa-cloud-moon",
  "fas fa-cloud-moon-rain",
  "fas fa-cloud-rain",
  "fas fa-cloud-showers-heavy",
  "fas fa-cloud-sun",
  "fas fa-cloud-sun-rain",
  "fab fa-cloudflare",
  "fab fa-cloudscale",
  "fab fa-cloudsmith",
  "fab fa-cloudversify",
  "fas fa-clover",
  "fab fa-cmplid",
  "fas fa-code",
  "fas fa-code-branch",
  "fas fa-code-commit",
  "fas fa-code-compare",
  "fas fa-code-fork",
  "fas fa-code-merge",
  "fas fa-code-pull-request",
  "fab fa-codepen",
  "fab fa-codiepie",
  "fas fa-coins",
  "fas fa-colon-sign",
  "far fa-comment",
  "fas fa-comment",
  "fas fa-comment-dollar",
  "far fa-comment-dots",
  "fas fa-comment-dots",
  "fas fa-comment-medical",
  "fas fa-comment-slash",
  "fas fa-comment-sms",
  "far fa-comments",
  "fas fa-comments",
  "fas fa-comments-dollar",
  "fas fa-compact-disc",
  "far fa-compass",
  "fas fa-compass",
  "fas fa-compass-drafting",
  "fas fa-compress",
  "fas fa-computer-mouse",
  "fab fa-confluence",
  "fab fa-connectdevelop",
  "fab fa-contao",
  "fas fa-cookie",
  "fas fa-cookie-bite",
  "far fa-copy",
  "fas fa-copy",
  "far fa-copyright",
  "fas fa-copyright",
  "fab fa-cotton-bureau",
  "fas fa-couch",
  "fab fa-cpanel",
  "fab fa-creative-commons",
  "fab fa-creative-commons-by",
  "fab fa-creative-commons-nc",
  "fab fa-creative-commons-nc-eu",
  "fab fa-creative-commons-nc-jp",
  "fab fa-creative-commons-nd",
  "fab fa-creative-commons-pd",
  "fab fa-creative-commons-pd-alt",
  "fab fa-creative-commons-remix",
  "fab fa-creative-commons-sa",
  "fab fa-creative-commons-sampling",
  "fab fa-creative-commons-sampling-plus",
  "fab fa-creative-commons-share",
  "fab fa-creative-commons-zero",
  "far fa-credit-card",
  "fas fa-credit-card",
  "fab fa-critical-role",
  "fas fa-crop",
  "fas fa-crop-simple",
  "fas fa-cross",
  "fas fa-crosshairs",
  "fas fa-crow",
  "fas fa-crown",
  "fas fa-crutch",
  "fas fa-cruzeiro-sign",
  "fab fa-css3",
  "fab fa-css3-alt",
  "fas fa-cube",
  "fas fa-cubes",
  "fab fa-cuttlefish",
  "fas fa-d",
  "fab fa-d-and-d",
  "fab fa-d-and-d-beyond",
  "fab fa-dailymotion",
  "fab fa-dashcube",
  "fas fa-database",
  "fab fa-deezer",
  "fas fa-delete-left",
  "fab fa-delicious",
  "fas fa-democrat",
  "fab fa-deploydog",
  "fab fa-deskpro",
  "fas fa-desktop",
  "fab fa-dev",
  "fab fa-deviantart",
  "fas fa-dharmachakra",
  "fab fa-dhl",
  "fas fa-diagram-next",
  "fas fa-diagram-predecessor",
  "fas fa-diagram-project",
  "fas fa-diagram-successor",
  "fas fa-diamond",
  "fas fa-diamond-turn-right",
  "fab fa-diaspora",
  "fas fa-dice",
  "fas fa-dice-d20",
  "fas fa-dice-d6",
  "fas fa-dice-five",
  "fas fa-dice-four",
  "fas fa-dice-one",
  "fas fa-dice-six",
  "fas fa-dice-three",
  "fas fa-dice-two",
  "fab fa-digg",
  "fab fa-digital-ocean",
  "fab fa-discord",
  "fab fa-discourse",
  "fas fa-disease",
  "fas fa-divide",
  "fas fa-dna",
  "fab fa-dochub",
  "fab fa-docker",
  "fas fa-dog",
  "fas fa-dollar-sign",
  "fas fa-dolly",
  "fas fa-dong-sign",
  "fas fa-door-closed",
  "fas fa-door-open",
  "fas fa-dove",
  "fas fa-down-left-and-up-right-to-center",
  "fas fa-down-long",
  "fas fa-download",
  "fab fa-draft2digital",
  "fas fa-dragon",
  "fas fa-draw-polygon",
  "fab fa-dribbble",
  "fab fa-dropbox",
  "fas fa-droplet",
  "fas fa-droplet-slash",
  "fas fa-drum",
  "fas fa-drum-steelpan",
  "fas fa-drumstick-bite",
  "fab fa-drupal",
  "fas fa-dumbbell",
  "fas fa-dumpster",
  "fas fa-dumpster-fire",
  "fas fa-dungeon",
  "fab fa-dyalog",
  "fas fa-e",
  "fas fa-ear-deaf",
  "fas fa-ear-listen",
  "fab fa-earlybirds",
  "fas fa-earth-africa",
  "fas fa-earth-americas",
  "fas fa-earth-asia",
  "fas fa-earth-europe",
  "fas fa-earth-oceania",
  "fab fa-ebay",
  "fab fa-edge",
  "fab fa-edge-legacy",
  "fas fa-egg",
  "fas fa-eject",
  "fab fa-elementor",
  "fas fa-elevator",
  "fas fa-ellipsis",
  "fas fa-ellipsis-vertical",
  "fab fa-ello",
  "fab fa-ember",
  "fab fa-empire",
  "far fa-envelope",
  "fas fa-envelope",
  "far fa-envelope-open",
  "fas fa-envelope-open",
  "fas fa-envelope-open-text",
  "fas fa-envelopes-bulk",
  "fab fa-envira",
  "fas fa-equals",
  "fas fa-eraser",
  "fab fa-erlang",
  "fab fa-ethereum",
  "fas fa-ethernet",
  "fab fa-etsy",
  "fas fa-euro-sign",
  "fab fa-evernote",
  "fas fa-exclamation",
  "fas fa-expand",
  "fab fa-expeditedssl",
  "far fa-eye",
  "fas fa-eye",
  "fas fa-eye-dropper",
  "fas fa-eye-low-vision",
  "far fa-eye-slash",
  "fas fa-eye-slash",
  "fas fa-f",
  "far fa-face-angry",
  "fas fa-face-angry",
  "far fa-face-dizzy",
  "fas fa-face-dizzy",
  "far fa-face-flushed",
  "fas fa-face-flushed",
  "far fa-face-frown",
  "fas fa-face-frown",
  "far fa-face-frown-open",
  "fas fa-face-frown-open",
  "far fa-face-grimace",
  "fas fa-face-grimace",
  "far fa-face-grin",
  "fas fa-face-grin",
  "far fa-face-grin-beam",
  "fas fa-face-grin-beam",
  "far fa-face-grin-beam-sweat",
  "fas fa-face-grin-beam-sweat",
  "far fa-face-grin-hearts",
  "fas fa-face-grin-hearts",
  "far fa-face-grin-squint",
  "fas fa-face-grin-squint",
  "far fa-face-grin-squint-tears",
  "fas fa-face-grin-squint-tears",
  "far fa-face-grin-stars",
  "fas fa-face-grin-stars",
  "far fa-face-grin-tears",
  "fas fa-face-grin-tears",
  "far fa-face-grin-tongue",
  "fas fa-face-grin-tongue",
  "far fa-face-grin-tongue-squint",
  "fas fa-face-grin-tongue-squint",
  "far fa-face-grin-tongue-wink",
  "fas fa-face-grin-tongue-wink",
  "far fa-face-grin-wide",
  "fas fa-face-grin-wide",
  "far fa-face-grin-wink",
  "fas fa-face-grin-wink",
  "far fa-face-kiss",
  "fas fa-face-kiss",
  "far fa-face-kiss-beam",
  "fas fa-face-kiss-beam",
  "far fa-face-kiss-wink-heart",
  "fas fa-face-kiss-wink-heart",
  "far fa-face-laugh",
  "fas fa-face-laugh",
  "far fa-face-laugh-beam",
  "fas fa-face-laugh-beam",
  "far fa-face-laugh-squint",
  "fas fa-face-laugh-squint",
  "far fa-face-laugh-wink",
  "fas fa-face-laugh-wink",
  "far fa-face-meh",
  "fas fa-face-meh",
  "far fa-face-meh-blank",
  "fas fa-face-meh-blank",
  "far fa-face-rolling-eyes",
  "fas fa-face-rolling-eyes",
  "far fa-face-sad-cry",
  "fas fa-face-sad-cry",
  "far fa-face-sad-tear",
  "fas fa-face-sad-tear",
  "far fa-face-smile",
  "fas fa-face-smile",
  "far fa-face-smile-beam",
  "fas fa-face-smile-beam",
  "far fa-face-smile-wink",
  "fas fa-face-smile-wink",
  "far fa-face-surprise",
  "fas fa-face-surprise",
  "far fa-face-tired",
  "fas fa-face-tired",
  "fab fa-facebook",
  "fab fa-facebook-f",
  "fab fa-facebook-messenger",
  "fas fa-fan",
  "fab fa-fantasy-flight-games",
  "fas fa-faucet",
  "fas fa-fax",
  "fas fa-feather",
  "fas fa-feather-pointed",
  "fab fa-fedex",
  "fab fa-fedora",
  "fab fa-figma",
  "far fa-file",
  "fas fa-file",
  "fas fa-file-arrow-down",
  "fas fa-file-arrow-up",
  "far fa-file-audio",
  "fas fa-file-audio",
  "far fa-file-code",
  "fas fa-file-code",
  "fas fa-file-contract",
  "fas fa-file-csv",
  "far fa-file-excel",
  "fas fa-file-excel",
  "fas fa-file-export",
  "far fa-file-image",
  "fas fa-file-image",
  "fas fa-file-import",
  "fas fa-file-invoice",
  "fas fa-file-invoice-dollar",
  "far fa-file-lines",
  "fas fa-file-lines",
  "fas fa-file-medical",
  "far fa-file-pdf",
  "fas fa-file-pdf",
  "far fa-file-powerpoint",
  "fas fa-file-powerpoint",
  "fas fa-file-prescription",
  "fas fa-file-signature",
  "far fa-file-video",
  "fas fa-file-video",
  "fas fa-file-waveform",
  "far fa-file-word",
  "fas fa-file-word",
  "far fa-file-zipper",
  "fas fa-file-zipper",
  "fas fa-fill",
  "fas fa-fill-drip",
  "fas fa-film",
  "fas fa-filter",
  "fas fa-filter-circle-dollar",
  "fas fa-filter-circle-xmark",
  "fas fa-fingerprint",
  "fas fa-fire",
  "fas fa-fire-extinguisher",
  "fas fa-fire-flame-curved",
  "fas fa-fire-flame-simple",
  "fab fa-firefox",
  "fab fa-firefox-browser",
  "fab fa-first-order",
  "fab fa-first-order-alt",
  "fab fa-firstdraft",
  "fas fa-fish",
  "far fa-flag",
  "fas fa-flag",
  "fas fa-flag-checkered",
  "fas fa-flag-usa",
  "fas fa-flask",
  "fab fa-flickr",
  "fab fa-flipboard",
  "far fa-floppy-disk",
  "fas fa-floppy-disk",
  "fas fa-florin-sign",
  "fab fa-fly",
  "far fa-folder",
  "fas fa-folder",
  "fas fa-folder-minus",
  "far fa-folder-open",
  "fas fa-folder-open",
  "fas fa-folder-plus",
  "fas fa-folder-tree",
  "fas fa-font",
  "fab fa-font-awesome",
  "far fa-font-awesome",
  "fas fa-font-awesome",
  "fab fa-fonticons",
  "fab fa-fonticons-fi",
  "fas fa-football",
  "fab fa-fort-awesome",
  "fab fa-fort-awesome-alt",
  "fab fa-forumbee",
  "fas fa-forward",
  "fas fa-forward-fast",
  "fas fa-forward-step",
  "fab fa-foursquare",
  "fas fa-franc-sign",
  "fab fa-free-code-camp",
  "fab fa-freebsd",
  "fas fa-frog",
  "fab fa-fulcrum",
  "far fa-futbol",
  "fas fa-futbol",
  "fas fa-g",
  "fab fa-galactic-republic",
  "fab fa-galactic-senate",
  "fas fa-gamepad",
  "fas fa-gas-pump",
  "fas fa-gauge",
  "fas fa-gauge-high",
  "fas fa-gauge-simple",
  "fas fa-gauge-simple-high",
  "fas fa-gavel",
  "fas fa-gear",
  "fas fa-gears",
  "fas fa-gem",
  "far fa-gem",
  "fas fa-genderless",
  "fab fa-get-pocket",
  "fab fa-gg",
  "fab fa-gg-circle",
  "fas fa-ghost",
  "fas fa-gift",
  "fas fa-gifts",
  "fab fa-git",
  "fab fa-git-alt",
  "fab fa-github",
  "fab fa-github-alt",
  "fab fa-gitkraken",
  "fab fa-gitlab",
  "fab fa-gitter",
  "fas fa-glasses",
  "fab fa-glide",
  "fab fa-glide-g",
  "fas fa-globe",
  "fab fa-gofore",
  "fab fa-golang",
  "fas fa-golf-ball-tee",
  "fab fa-goodreads",
  "fab fa-goodreads-g",
  "fab fa-google",
  "fab fa-google-drive",
  "fab fa-google-pay",
  "fab fa-google-play",
  "fab fa-google-plus",
  "fab fa-google-plus-g",
  "fab fa-google-wallet",
  "fas fa-gopuram",
  "fas fa-graduation-cap",
  "fab fa-gratipay",
  "fab fa-grav",
  "fas fa-greater-than",
  "fas fa-greater-than-equal",
  "fas fa-grip",
  "fas fa-grip-lines",
  "fas fa-grip-lines-vertical",
  "fas fa-grip-vertical",
  "fab fa-gripfire",
  "fab fa-grunt",
  "fas fa-guarani-sign",
  "fab fa-guilded",
  "fas fa-guitar",
  "fab fa-gulp",
  "fas fa-gun",
  "fas fa-h",
  "fab fa-hacker-news",
  "fab fa-hackerrank",
  "fas fa-hammer",
  "fas fa-hamsa",
  "far fa-hand",
  "fas fa-hand",
  "far fa-hand-back-fist",
  "fas fa-hand-back-fist",
  "fas fa-hand-dots",
  "fas fa-hand-fist",
  "fas fa-hand-holding",
  "fas fa-hand-holding-dollar",
  "fas fa-hand-holding-droplet",
  "fas fa-hand-holding-heart",
  "fas fa-hand-holding-medical",
  "far fa-hand-lizard",
  "fas fa-hand-lizard",
  "fas fa-hand-middle-finger",
  "far fa-hand-peace",
  "fas fa-hand-peace",
  "far fa-hand-point-down",
  "fas fa-hand-point-down",
  "far fa-hand-point-left",
  "fas fa-hand-point-left",
  "far fa-hand-point-right",
  "fas fa-hand-point-right",
  "far fa-hand-point-up",
  "fas fa-hand-point-up",
  "far fa-hand-pointer",
  "fas fa-hand-pointer",
  "far fa-hand-scissors",
  "fas fa-hand-scissors",
  "fas fa-hand-sparkles",
  "far fa-hand-spock",
  "fas fa-hand-spock",
  "fas fa-hands",
  "fas fa-hands-asl-interpreting",
  "fas fa-hands-bubbles",
  "fas fa-hands-clapping",
  "fas fa-hands-holding",
  "fas fa-hands-praying",
  "far fa-handshake",
  "fas fa-handshake",
  "fas fa-handshake-angle",
  "fas fa-handshake-simple-slash",
  "fas fa-handshake-slash",
  "fas fa-hanukiah",
  "far fa-hard-drive",
  "fas fa-hard-drive",
  "fab fa-hashnode",
  "fas fa-hashtag",
  "fas fa-hat-cowboy",
  "fas fa-hat-cowboy-side",
  "fas fa-hat-wizard",
  "fas fa-head-side-cough",
  "fas fa-head-side-cough-slash",
  "fas fa-head-side-mask",
  "fas fa-head-side-virus",
  "fas fa-heading",
  "fas fa-headphones",
  "fas fa-headphones-simple",
  "fas fa-headset",
  "far fa-heart",
  "fas fa-heart",
  "fas fa-heart-crack",
  "fas fa-heart-pulse",
  "fas fa-helicopter",
  "fas fa-helmet-safety",
  "fas fa-highlighter",
  "fas fa-hippo",
  "fab fa-hips",
  "fab fa-hire-a-helper",
  "fab fa-hive",
  "fas fa-hockey-puck",
  "fas fa-holly-berry",
  "fab fa-hooli",
  "fab fa-hornbill",
  "fas fa-horse",
  "fas fa-horse-head",
  "far fa-hospital",
  "fas fa-hospital",
  "fas fa-hospital-user",
  "fas fa-hot-tub-person",
  "fas fa-hotdog",
  "fas fa-hotel",
  "fab fa-hotjar",
  "far fa-hourglass",
  "fas fa-hourglass",
  "fas fa-hourglass-end",
  "far fa-hourglass-half",
  "fas fa-hourglass-half",
  "fas fa-hourglass-start",
  "fas fa-house",
  "fas fa-house-chimney",
  "fas fa-house-chimney-crack",
  "fas fa-house-chimney-medical",
  "fas fa-house-chimney-user",
  "fas fa-house-chimney-window",
  "fas fa-house-crack",
  "fas fa-house-laptop",
  "fas fa-house-medical",
  "fas fa-house-user",
  "fab fa-houzz",
  "fas fa-hryvnia-sign",
  "fab fa-html5",
  "fab fa-hubspot",
  "fas fa-i",
  "fas fa-i-cursor",
  "fas fa-ice-cream",
  "fas fa-icicles",
  "fas fa-icons",
  "far fa-id-badge",
  "fas fa-id-badge",
  "far fa-id-card",
  "fas fa-id-card",
  "fas fa-id-card-clip",
  "fab fa-ideal",
  "fas fa-igloo",
  "far fa-image",
  "fas fa-image",
  "fas fa-image-portrait",
  "far fa-images",
  "fas fa-images",
  "fab fa-imdb",
  "fas fa-inbox",
  "fas fa-indent",
  "fas fa-indian-rupee-sign",
  "fas fa-industry",
  "fas fa-infinity",
  "fas fa-info",
  "fab fa-instagram",
  "fab fa-instalod",
  "fab fa-intercom",
  "fab fa-internet-explorer",
  "fab fa-invision",
  "fab fa-ioxhost",
  "fas fa-italic",
  "fab fa-itch-io",
  "fab fa-itunes",
  "fab fa-itunes-note",
  "fas fa-j",
  "fab fa-java",
  "fas fa-jedi",
  "fab fa-jedi-order",
  "fab fa-jenkins",
  "fas fa-jet-fighter",
  "fab fa-jira",
  "fab fa-joget",
  "fas fa-joint",
  "fab fa-joomla",
  "fab fa-js",
  "fab fa-jsfiddle",
  "fas fa-k",
  "fas fa-kaaba",
  "fab fa-kaggle",
  "fas fa-key",
  "fab fa-keybase",
  "far fa-keyboard",
  "fas fa-keyboard",
  "fab fa-keycdn",
  "fas fa-khanda",
  "fab fa-kickstarter",
  "fab fa-kickstarter-k",
  "fas fa-kip-sign",
  "fas fa-kit-medical",
  "fas fa-kiwi-bird",
  "fab fa-korvue",
  "fas fa-l",
  "fas fa-landmark",
  "fas fa-language",
  "fas fa-laptop",
  "fas fa-laptop-code",
  "fas fa-laptop-medical",
  "fab fa-laravel",
  "fas fa-lari-sign",
  "fab fa-lastfm",
  "fas fa-layer-group",
  "fas fa-leaf",
  "fab fa-leanpub",
  "fas fa-left-long",
  "fas fa-left-right",
  "far fa-lemon",
  "fas fa-lemon",
  "fab fa-less",
  "fas fa-less-than",
  "fas fa-less-than-equal",
  "far fa-life-ring",
  "fas fa-life-ring",
  "far fa-lightbulb",
  "fas fa-lightbulb",
  "fab fa-line",
  "fas fa-link",
  "fas fa-link-slash",
  "fab fa-linkedin",
  "fab fa-linkedin-in",
  "fab fa-linode",
  "fab fa-linux",
  "fas fa-lira-sign",
  "fas fa-list",
  "fas fa-list-check",
  "fas fa-list-ol",
  "fas fa-list-ul",
  "fas fa-litecoin-sign",
  "fas fa-location-arrow",
  "fas fa-location-crosshairs",
  "fas fa-location-dot",
  "fas fa-location-pin",
  "fas fa-lock",
  "fas fa-lock-open",
  "fas fa-lungs",
  "fas fa-lungs-virus",
  "fab fa-lyft",
  "fas fa-m",
  "fab fa-magento",
  "fas fa-magnet",
  "fas fa-magnifying-glass",
  "fas fa-magnifying-glass-dollar",
  "fas fa-magnifying-glass-location",
  "fas fa-magnifying-glass-minus",
  "fas fa-magnifying-glass-plus",
  "fab fa-mailchimp",
  "fas fa-manat-sign",
  "fab fa-mandalorian",
  "far fa-map",
  "fas fa-map",
  "fas fa-map-location",
  "fas fa-map-location-dot",
  "fas fa-map-pin",
  "fab fa-markdown",
  "fas fa-marker",
  "fas fa-mars",
  "fas fa-mars-and-venus",
  "fas fa-mars-double",
  "fas fa-mars-stroke",
  "fas fa-mars-stroke-right",
  "fas fa-mars-stroke-up",
  "fas fa-martini-glass",
  "fas fa-martini-glass-citrus",
  "fas fa-martini-glass-empty",
  "fas fa-mask",
  "fas fa-mask-face",
  "fas fa-masks-theater",
  "fab fa-mastodon",
  "fab fa-maxcdn",
  "fas fa-maximize",
  "fab fa-mdb",
  "fas fa-medal",
  "fab fa-medapps",
  "fab fa-medium",
  "fab fa-medrt",
  "fab fa-meetup",
  "fab fa-megaport",
  "fas fa-memory",
  "fab fa-mendeley",
  "fas fa-menorah",
  "fas fa-mercury",
  "far fa-message",
  "fas fa-message",
  "fas fa-meteor",
  "fab fa-microblog",
  "fas fa-microchip",
  "fas fa-microphone",
  "fas fa-microphone-lines",
  "fas fa-microphone-lines-slash",
  "fas fa-microphone-slash",
  "fas fa-microscope",
  "fab fa-microsoft",
  "fas fa-mill-sign",
  "fas fa-minimize",
  "fas fa-minus",
  "fas fa-mitten",
  "fab fa-mix",
  "fab fa-mixcloud",
  "fab fa-mixer",
  "fab fa-mizuni",
  "fas fa-mobile",
  "fas fa-mobile-button",
  "fas fa-mobile-screen-button",
  "fab fa-modx",
  "fab fa-monero",
  "fas fa-money-bill",
  "far fa-money-bill-1",
  "fas fa-money-bill-1",
  "fas fa-money-bill-1-wave",
  "fas fa-money-bill-wave",
  "fas fa-money-check",
  "fas fa-money-check-dollar",
  "fas fa-monument",
  "far fa-moon",
  "fas fa-moon",
  "fas fa-mortar-pestle",
  "fas fa-mosque",
  "fas fa-motorcycle",
  "fas fa-mountain",
  "fas fa-mug-hot",
  "fas fa-mug-saucer",
  "fas fa-music",
  "fas fa-n",
  "fas fa-naira-sign",
  "fab fa-napster",
  "fab fa-neos",
  "fas fa-network-wired",
  "fas fa-neuter",
  "far fa-newspaper",
  "fas fa-newspaper",
  "fab fa-nimblr",
  "fab fa-node",
  "fab fa-node-js",
  "fas fa-not-equal",
  "far fa-note-sticky",
  "fas fa-note-sticky",
  "fas fa-notes-medical",
  "fab fa-npm",
  "fab fa-ns8",
  "fab fa-nutritionix",
  "fas fa-o",
  "far fa-object-group",
  "fas fa-object-group",
  "far fa-object-ungroup",
  "fas fa-object-ungroup",
  "fab fa-octopus-deploy",
  "fab fa-odnoklassniki",
  "fas fa-oil-can",
  "fab fa-old-republic",
  "fas fa-om",
  "fab fa-opencart",
  "fab fa-openid",
  "fab fa-opera",
  "fab fa-optin-monster",
  "fab fa-orcid",
  "fab fa-osi",
  "fas fa-otter",
  "fas fa-outdent",
  "fas fa-p",
  "fab fa-padlet",
  "fab fa-page4",
  "fab fa-pagelines",
  "fas fa-pager",
  "fas fa-paint-roller",
  "fas fa-paintbrush",
  "fas fa-palette",
  "fab fa-palfed",
  "fas fa-pallet",
  "fas fa-panorama",
  "far fa-paper-plane",
  "fas fa-paper-plane",
  "fas fa-paperclip",
  "fas fa-parachute-box",
  "fas fa-paragraph",
  "fas fa-passport",
  "far fa-paste",
  "fas fa-paste",
  "fab fa-patreon",
  "fas fa-pause",
  "fas fa-paw",
  "fab fa-paypal",
  "fas fa-peace",
  "fas fa-pen",
  "fas fa-pen-clip",
  "fas fa-pen-fancy",
  "fas fa-pen-nib",
  "fas fa-pen-ruler",
  "far fa-pen-to-square",
  "fas fa-pen-to-square",
  "fas fa-pencil",
  "fas fa-people-arrows",
  "fas fa-people-carry-box",
  "fas fa-pepper-hot",
  "fab fa-perbyte",
  "fas fa-percent",
  "fab fa-periscope",
  "fas fa-person",
  "fas fa-person-biking",
  "fas fa-person-booth",
  "fas fa-person-dots-from-line",
  "fas fa-person-dress",
  "fas fa-person-hiking",
  "fas fa-person-praying",
  "fas fa-person-running",
  "fas fa-person-skating",
  "fas fa-person-skiing",
  "fas fa-person-skiing-nordic",
  "fas fa-person-snowboarding",
  "fas fa-person-swimming",
  "fas fa-person-walking",
  "fas fa-person-walking-with-cane",
  "fas fa-peseta-sign",
  "fas fa-peso-sign",
  "fab fa-phabricator",
  "fab fa-phoenix-framework",
  "fab fa-phoenix-squadron",
  "fas fa-phone",
  "fas fa-phone-flip",
  "fas fa-phone-slash",
  "fas fa-phone-volume",
  "fas fa-photo-film",
  "fab fa-php",
  "fab fa-pied-piper",
  "fab fa-pied-piper-alt",
  "fab fa-pied-piper-hat",
  "fab fa-pied-piper-pp",
  "fas fa-piggy-bank",
  "fas fa-pills",
  "fab fa-pinterest",
  "fab fa-pinterest-p",
  "fab fa-pix",
  "fas fa-pizza-slice",
  "fas fa-place-of-worship",
  "fas fa-plane",
  "fas fa-plane-arrival",
  "fas fa-plane-departure",
  "fas fa-plane-slash",
  "fas fa-play",
  "fab fa-playstation",
  "fas fa-plug",
  "fas fa-plus",
  "fas fa-plus-minus",
  "fas fa-podcast",
  "fas fa-poo",
  "fas fa-poo-storm",
  "fas fa-poop",
  "fas fa-power-off",
  "fas fa-prescription",
  "fas fa-prescription-bottle",
  "fas fa-prescription-bottle-medical",
  "fas fa-print",
  "fab fa-product-hunt",
  "fas fa-pump-medical",
  "fas fa-pump-soap",
  "fab fa-pushed",
  "fas fa-puzzle-piece",
  "fab fa-python",
  "fas fa-q",
  "fab fa-qq",
  "fas fa-qrcode",
  "fas fa-question",
  "fab fa-quinscape",
  "fab fa-quora",
  "fas fa-quote-left",
  "fas fa-quote-right",
  "fas fa-r",
  "fab fa-r-project",
  "fas fa-radiation",
  "fas fa-rainbow",
  "fab fa-raspberry-pi",
  "fab fa-ravelry",
  "fab fa-react",
  "fab fa-reacteurope",
  "fab fa-readme",
  "fab fa-rebel",
  "fas fa-receipt",
  "fas fa-record-vinyl",
  "fas fa-rectangle-ad",
  "far fa-rectangle-list",
  "fas fa-rectangle-list",
  "far fa-rectangle-xmark",
  "fas fa-rectangle-xmark",
  "fas fa-recycle",
  "fab fa-red-river",
  "fab fa-reddit",
  "fab fa-reddit-alien",
  "fab fa-redhat",
  "far fa-registered",
  "fas fa-registered",
  "fab fa-renren",
  "fas fa-repeat",
  "fas fa-reply",
  "fas fa-reply-all",
  "fab fa-replyd",
  "fas fa-republican",
  "fab fa-researchgate",
  "fab fa-resolving",
  "fas fa-restroom",
  "fas fa-retweet",
  "fab fa-rev",
  "fas fa-ribbon",
  "fas fa-right-from-bracket",
  "fas fa-right-left",
  "fas fa-right-long",
  "fas fa-right-to-bracket",
  "fas fa-ring",
  "fas fa-road",
  "fas fa-robot",
  "fas fa-rocket",
  "fab fa-rocketchat",
  "fab fa-rockrms",
  "fas fa-rotate",
  "fas fa-rotate-left",
  "fas fa-rotate-right",
  "fas fa-route",
  "fas fa-rss",
  "fas fa-ruble-sign",
  "fas fa-ruler",
  "fas fa-ruler-combined",
  "fas fa-ruler-horizontal",
  "fas fa-ruler-vertical",
  "fas fa-rupee-sign",
  "fas fa-rupiah-sign",
  "fab fa-rust",
  "fas fa-s",
  "fab fa-safari",
  "fas fa-sailboat",
  "fab fa-salesforce",
  "fab fa-sass",
  "fas fa-satellite",
  "fas fa-satellite-dish",
  "fas fa-scale-balanced",
  "fas fa-scale-unbalanced",
  "fas fa-scale-unbalanced-flip",
  "fab fa-schlix",
  "fas fa-school",
  "fas fa-scissors",
  "fas fa-screwdriver",
  "fas fa-screwdriver-wrench",
  "fab fa-scribd",
  "fas fa-scroll",
  "fas fa-scroll-torah",
  "fas fa-sd-card",
  "fab fa-searchengin",
  "fas fa-section",
  "fas fa-seedling",
  "fab fa-sellcast",
  "fab fa-sellsy",
  "fas fa-server",
  "fab fa-servicestack",
  "fas fa-shapes",
  "fas fa-share",
  "far fa-share-from-square",
  "fas fa-share-from-square",
  "fas fa-share-nodes",
  "fas fa-shekel-sign",
  "fas fa-shield",
  "fas fa-shield-virus",
  "fas fa-ship",
  "fas fa-shirt",
  "fab fa-shirtsinbulk",
  "fas fa-shoe-prints",
  "fas fa-shop",
  "fas fa-shop-slash",
  "fab fa-shopify",
  "fab fa-shopware",
  "fas fa-shower",
  "fas fa-shrimp",
  "fas fa-shuffle",
  "fas fa-shuttle-space",
  "fas fa-sign-hanging",
  "fas fa-signal",
  "fas fa-signature",
  "fas fa-signs-post",
  "fas fa-sim-card",
  "fab fa-simplybuilt",
  "fas fa-sink",
  "fab fa-sistrix",
  "fas fa-sitemap",
  "fab fa-sith",
  "fab fa-sitrox",
  "fab fa-sketch",
  "fas fa-skull",
  "fas fa-skull-crossbones",
  "fab fa-skyatlas",
  "fab fa-skype",
  "fab fa-slack",
  "fas fa-slash",
  "fas fa-sleigh",
  "fas fa-sliders",
  "fab fa-slideshare",
  "fas fa-smog",
  "fas fa-smoking",
  "fab fa-snapchat",
  "far fa-snowflake",
  "fas fa-snowflake",
  "fas fa-snowman",
  "fas fa-snowplow",
  "fas fa-soap",
  "fas fa-socks",
  "fas fa-solar-panel",
  "fas fa-sort",
  "fas fa-sort-down",
  "fas fa-sort-up",
  "fab fa-soundcloud",
  "fab fa-sourcetree",
  "fas fa-spa",
  "fas fa-spaghetti-monster-flying",
  "fab fa-speakap",
  "fab fa-speaker-deck",
  "fas fa-spell-check",
  "fas fa-spider",
  "fas fa-spinner",
  "fas fa-splotch",
  "fas fa-spoon",
  "fab fa-spotify",
  "fas fa-spray-can",
  "fas fa-spray-can-sparkles",
  "far fa-square",
  "fas fa-square",
  "fas fa-square-arrow-up-right",
  "fab fa-behance-square",
  "far fa-square-caret-down",
  "fas fa-square-caret-down",
  "far fa-square-caret-left",
  "fas fa-square-caret-left",
  "far fa-square-caret-right",
  "fas fa-square-caret-right",
  "far fa-square-caret-up",
  "fas fa-square-caret-up",
  "far fa-square-check",
  "fas fa-square-check",
  "fab fa-dribbble-square",
  "fas fa-square-envelope",
  "fab fa-facebook-square",
  "fab fa-square-font-awesome",
  "fab fa-square-font-awesome-stroke",
  "far fa-square-full",
  "fas fa-square-full",
  "fab fa-git-square",
  "fab fa-github-square",
  "fab fa-google-plus-square",
  "fas fa-square-h",
  "fab fa-hacker-news-square",
  "fab fa-instagram-square",
  "fab fa-js-square",
  "fab fa-lastfm-square",
  "far fa-square-minus",
  "fas fa-square-minus",
  "fab fa-odnoklassniki-square",
  "fas fa-square-parking",
  "fas fa-square-pen",
  "fas fa-square-phone",
  "fas fa-square-phone-flip",
  "fab fa-pied-piper-square",
  "fab fa-pinterest-square",
  "far fa-square-plus",
  "fas fa-square-plus",
  "fas fa-square-poll-horizontal",
  "fas fa-square-poll-vertical",
  "fab fa-reddit-square",
  "fas fa-square-root-variable",
  "fas fa-square-rss",
  "fas fa-square-share-nodes",
  "fab fa-snapchat-square",
  "fab fa-steam-square",
  "fab fa-tumblr-square",
  "fab fa-twitter-square",
  "fas fa-square-up-right",
  "fab fa-viadeo-square",
  "fab fa-square-square",
  "fab fa-whatsapp-square",
  "fab fa-xing-square",
  "fas fa-square-xmark",
  "fab fa-youtube-square",
  "fab fa-squarespace",
  "fab fa-stack-exchange",
  "fab fa-stack-overflow",
  "fab fa-stackpath",
  "fas fa-stairs",
  "fas fa-stamp",
  "far fa-star",
  "fas fa-star",
  "fas fa-star-and-crescent",
  "far fa-star-half",
  "fas fa-star-half",
  "far fa-star-half-stroke",
  "fas fa-star-half-stroke",
  "fas fa-star-of-david",
  "fas fa-star-of-life",
  "fab fa-staylinked",
  "fab fa-steam",
  "fab fa-steam-symbol",
  "fas fa-sterling-sign",
  "fas fa-stethoscope",
  "fab fa-sticker-mule",
  "fas fa-stop",
  "fas fa-stopwatch",
  "fas fa-stopwatch-20",
  "fas fa-store",
  "fas fa-store-slash",
  "fab fa-strava",
  "fas fa-street-view",
  "fas fa-strikethrough",
  "fab fa-stripe",
  "fab fa-stripe-s",
  "fas fa-stroopwafel",
  "fab fa-studiovinari",
  "fab fa-stumbleupon",
  "fab fa-stumbleupon-circle",
  "fas fa-subscript",
  "fas fa-suitcase",
  "fas fa-suitcase-medical",
  "fas fa-suitcase-rolling",
  "far fa-sun",
  "fas fa-sun",
  "fab fa-superpowers",
  "fas fa-superscript",
  "fab fa-supple",
  "fab fa-suse",
  "fas fa-swatchbook",
  "fab fa-swift",
  "fab fa-symfony",
  "fas fa-synagogue",
  "fas fa-syringe",
  "fas fa-t",
  "fas fa-table",
  "fas fa-table-cells",
  "fas fa-table-cells-large",
  "fas fa-table-columns",
  "fas fa-table-list",
  "fas fa-table-tennis-paddle-ball",
  "fas fa-tablet",
  "fas fa-tablet-button",
  "fas fa-tablet-screen-button",
  "fas fa-tablets",
  "fas fa-tachograph-digital",
  "fas fa-tag",
  "fas fa-tags",
  "fas fa-tape",
  "fas fa-taxi",
  "fab fa-teamspeak",
  "fas fa-teeth",
  "fas fa-teeth-open",
  "fab fa-telegram",
  "fas fa-temperature-empty",
  "fas fa-temperature-full",
  "fas fa-temperature-half",
  "fas fa-temperature-high",
  "fas fa-temperature-low",
  "fas fa-temperature-quarter",
  "fas fa-temperature-three-quarters",
  "fab fa-tencent-weibo",
  "fas fa-tenge-sign",
  "fas fa-terminal",
  "fas fa-text-height",
  "fas fa-text-slash",
  "fas fa-text-width",
  "fab fa-the-red-yeti",
  "fab fa-themeco",
  "fab fa-themeisle",
  "fas fa-thermometer",
  "fab fa-think-peaks",
  "far fa-thumbs-down",
  "fas fa-thumbs-down",
  "far fa-thumbs-up",
  "fas fa-thumbs-up",
  "fas fa-thumbtack",
  "fas fa-ticket",
  "fas fa-ticket-simple",
  "fab fa-tiktok",
  "fas fa-timeline",
  "fas fa-toggle-off",
  "fas fa-toggle-on",
  "fas fa-toilet",
  "fas fa-toilet-paper",
  "fas fa-toilet-paper-slash",
  "fas fa-toolbox",
  "fas fa-tooth",
  "fas fa-torii-gate",
  "fas fa-tower-broadcast",
  "fas fa-tractor",
  "fab fa-trade-federation",
  "fas fa-trademark",
  "fas fa-traffic-light",
  "fas fa-trailer",
  "fas fa-train",
  "fas fa-train-subway",
  "fas fa-train-tram",
  "fas fa-transgender",
  "fas fa-trash",
  "fas fa-trash-arrow-up",
  "far fa-trash-can",
  "fas fa-trash-can",
  "fas fa-trash-can-arrow-up",
  "fas fa-tree",
  "fab fa-trello",
  "fas fa-triangle-exclamation",
  "fas fa-trophy",
  "fas fa-truck",
  "fas fa-truck-fast",
  "fas fa-truck-medical",
  "fas fa-truck-monster",
  "fas fa-truck-moving",
  "fas fa-truck-pickup",
  "fas fa-truck-ramp-box",
  "fas fa-tty",
  "fab fa-tumblr",
  "fas fa-turkish-lira-sign",
  "fas fa-turn-down",
  "fas fa-turn-up",
  "fas fa-tv",
  "fab fa-twitch",
  "fab fa-twitter",
  "fab fa-typo3",
  "fas fa-u",
  "fab fa-uber",
  "fab fa-ubuntu",
  "fab fa-uikit",
  "fab fa-umbraco",
  "fas fa-umbrella",
  "fas fa-umbrella-beach",
  "fab fa-uncharted",
  "fas fa-underline",
  "fab fa-uniregistry",
  "fab fa-unity",
  "fas fa-universal-access",
  "fas fa-unlock",
  "fas fa-unlock-keyhole",
  "fab fa-unsplash",
  "fab fa-untappd",
  "fas fa-up-down",
  "fas fa-up-down-left-right",
  "fas fa-up-long",
  "fas fa-up-right-and-down-left-from-center",
  "fas fa-up-right-from-square",
  "fas fa-upload",
  "fab fa-ups",
  "fab fa-usb",
  "far fa-user",
  "fas fa-user",
  "fas fa-user-astronaut",
  "fas fa-user-check",
  "fas fa-user-clock",
  "fas fa-user-doctor",
  "fas fa-user-gear",
  "fas fa-user-graduate",
  "fas fa-user-group",
  "fas fa-user-injured",
  "fas fa-user-large",
  "fas fa-user-large-slash",
  "fas fa-user-lock",
  "fas fa-user-minus",
  "fas fa-user-ninja",
  "fas fa-user-nurse",
  "fas fa-user-pen",
  "fas fa-user-plus",
  "fas fa-user-secret",
  "fas fa-user-shield",
  "fas fa-user-slash",
  "fas fa-user-tag",
  "fas fa-user-tie",
  "fas fa-user-xmark",
  "fas fa-users",
  "fas fa-users-gear",
  "fas fa-users-slash",
  "fab fa-usps",
  "fab fa-ussunnah",
  "fas fa-utensils",
  "fas fa-v",
  "fab fa-vaadin",
  "fas fa-van-shuttle",
  "fas fa-vault",
  "fas fa-vector-square",
  "fas fa-venus",
  "fas fa-venus-double",
  "fas fa-venus-mars",
  "fas fa-vest",
  "fas fa-vest-patches",
  "fab fa-viacoin",
  "fab fa-viadeo",
  "fas fa-vial",
  "fas fa-vials",
  "fab fa-viber",
  "fas fa-video",
  "fas fa-video-slash",
  "fas fa-vihara",
  "fab fa-vimeo",
  "fab fa-vimeo-v",
  "fab fa-vine",
  "fas fa-virus",
  "fas fa-virus-covid",
  "fas fa-virus-covid-slash",
  "fas fa-virus-slash",
  "fas fa-viruses",
  "fab fa-vk",
  "fab fa-vnv",
  "fas fa-voicemail",
  "fas fa-volcano",
  "fas fa-volleyball",
  "fas fa-volume-high",
  "fas fa-volume-low",
  "fas fa-volume-off",
  "fas fa-volume-xmark",
  "fas fa-vr-cardboard",
  "fab fa-vuejs",
  "fas fa-w",
  "fas fa-wallet",
  "fas fa-wand-magic",
  "fas fa-wand-magic-sparkles",
  "fas fa-wand-sparkles",
  "fas fa-warehouse",
  "fab fa-watchman-monitoring",
  "fas fa-water",
  "fas fa-water-ladder",
  "fas fa-wave-square",
  "fab fa-waze",
  "fab fa-weebly",
  "fab fa-weibo",
  "fas fa-weight-hanging",
  "fas fa-weight-scale",
  "fab fa-weixin",
  "fab fa-whatsapp",
  "fas fa-wheelchair",
  "fas fa-whiskey-glass",
  "fab fa-whmcs",
  "fas fa-wifi",
  "fab fa-wikipedia-w",
  "fas fa-wind",
  "far fa-window-maximize",
  "fas fa-window-maximize",
  "far fa-window-minimize",
  "fas fa-window-minimize",
  "far fa-window-restore",
  "fas fa-window-restore",
  "fab fa-windows",
  "fas fa-wine-bottle",
  "fas fa-wine-glass",
  "fas fa-wine-glass-empty",
  "fab fa-wirsindhandwerk",
  "fab fa-wix",
  "fab fa-wizards-of-the-coast",
  "fab fa-wodu",
  "fab fa-wolf-pack-battalion",
  "fas fa-won-sign",
  "fab fa-wordpress",
  "fab fa-wordpress-simple",
  "fab fa-wpbeginner",
  "fab fa-wpexplorer",
  "fab fa-wpforms",
  "fab fa-wpressr",
  "fas fa-wrench",
  "fas fa-x",
  "fas fa-x-ray",
  "fab fa-xbox",
  "fab fa-xing",
  "fas fa-xmark",
  "fas fa-y",
  "fab fa-y-combinator",
  "fab fa-yahoo",
  "fab fa-yammer",
  "fab fa-yandex",
  "fab fa-yandex-international",
  "fab fa-yarn",
  "fab fa-yelp",
  "fas fa-yen-sign",
  "fas fa-yin-yang",
  "fab fa-yoast",
  "fab fa-youtube",
  "fas fa-z",
  "fab fa-zhihu"
];

export default icons;